<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t("message.new_report") }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false"
                                @click="submit">{{ $t("message.save_and_close") }}</el-button>
                            <el-button @click="close()">{{
                                $t("message.close")
                            }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div id="myDiv">
            <div class="itme-process" v-for="count in counts" :key="count">
                <componentInside :is_last="count == counts" @parent-event="add"></componentInside>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selectOrderService from "@/components/inventory-select/select-order-service";
import drawerChild from "@/utils/mixins/drawer-child";
import componentInside from "./component";
import users from "@/components/selects/user_list";
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawerChild],
    data() {
        return {
            form_processes: [],
            processesShow: false,
            counts: 1,
        };
    },
    components: {
        selectOrderService,
        users,
        componentInside,
    },
    created() { },
    computed: {
        ...mapGetters({
            rules: "expresses/rules",
            model: "expresses/model",
            columns: "expresses/columns",
            processes: "processes/list",
        }),
    },
    // async mounted() {
    //   if (this.processes && this.processes.length === 0) await this.loadProcess();
    //   await this.get();
    //   console.log(this.form_processes);
    // },

    methods: {
        // get() {
        //   this.form_processes = this.processes;
        // },
        ...mapActions({
            save: "expresses/store",
            loadProcess: "processes/index",
            empty: "expresses/empty",
        }),
        // parentEvent(value) {
        //   const query = { service_id: value };
        //   this.loadProcess(query);
        // },
        add() {
            this.counts++;
        },
        submit(close = true) {
            this.close();
            this.parent().listChanged();
            // this.$refs["form"].validate((valid) => {
            //   if (valid) {
            //     this.loadingButton = true;

            //     this.form.processes = this.form_processes;
            //     this.save(this.form)
            //       .then((res) => {
            //         this.loadingButton = false;
            //         this.$notify({
            //     title: 'Успешно',
            //     type: "success",
            //     offset: 130,
            //     message: res.message
            //   });
            this.resetForm("form");
            //         //   if (close == true) {
            //         //   }
            //       })
            //       .catch((err) => {
            //         this.loadingButton = false;
            //         this.$notify({
            //     title: "Ошибка",
            //         type: "error",
            //             offset: 130,
            //                 message: err.error.message,
            //   });
            //       });
            //   }
            // });
        },
        afterOpened() {
            this.form = JSON.parse(JSON.stringify(this.model));
        },
        afterClosed() {
            this.empty();
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit("c-close", { drawer: "drawerCreate" });
        },
    },
};
</script>
<style lang="scss">
#myDiv {
    position: relative;
    overflow: hidden;
}
</style>