<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_report") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <export-excel
                v-can="'organizations.excel'"
                class="btn excel_btn"
                :data="model.data"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                worksheet="Отчеты"
                name="Отчеты.xls"
              >
                <el-button size="mini">
                  <i class="el-icon-document-delete"></i> Excel
                </el-button>
              </export-excel>
              <el-button @click="resetForm('form')">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div id="myDiv" class="p-3">
      <table class="table table-bordered table-hover ">
        <thead>
          <th>{{ $t("message.doctor") }}</th>
          <th>{{ $t("message.count") }}</th>
          <th>{{ $t("message.amount") }}</th>
        </thead>
        <tbody>
          <tr v-for="user in model.data" :key="user.id">
            <td>{{ user.doctor.name }}</td>
            <td>{{ user.count }}</td>
            <td>{{ user.total_amount }}</td>
          </tr>
        </tbody>
      </table>

      <div>
       <el-row :gutter="20">
          <el-col :span="24"> <el-card class="box-card">
            <div class="itme-p-r">Общая сумма выплат: <b> {{ model.total_amount }} сум</b> </div> 
         </el-card> </el-col>
        </el-row>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawerChild],
  data() {
    return {
      excel_fields: {},
      list: [],
    };
  },
  async mounted() {
    await this.controlExcelData();
  },
  computed: {
    ...mapGetters({
      rules: "dailyProcessReport/rules",
      model: "dailyProcessReport/model",
      columns: "dailyProcessReport/columns",
      processes: "processes/list",
    }),
  },

  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      save: "dailyProcessReport/store",
      loadProcess: "processes/index",
      empty: "dailyProcessReport/empty",
    }),
   
    controlExcelData() {
      this.excel_fields = {
        Врач: "doctor.name",
        Количество: "count",
        'Общая сумма': "total_amount",

      };

    
    },
    submit(close = true) {
      this.close();
      this.parent().listChanged();
    },
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model.data));
    },
    afterClosed() {
      this.empty();
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerShow" });
    },
  },
};
</script>
